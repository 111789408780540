export enum InputTypes {
    Text,
    TextArea,
    CheckBox,
    FridgeTemp,
    FreezerTemp,
    HotHoldingTemp,
    CookingTemp,
    Signature,
    Select,
    Message,
    Timestamp,
    CoolingTemp,
    HotProbeTemp,
    ColdProbeTemp,
    ColdHoldingTemp,
    RegenerationTemp,
    CheckBoxNo
}

export function isTemperatureInput(type: number) : boolean {
    return (type === InputTypes.FridgeTemp || 
        type === InputTypes.FreezerTemp || 
        type === InputTypes.HotHoldingTemp || 
        type === InputTypes.CookingTemp || 
        type === InputTypes.CoolingTemp || 
        type === InputTypes.HotProbeTemp || 
        type === InputTypes.ColdProbeTemp || 
        type === InputTypes.ColdHoldingTemp || 
        type === InputTypes.RegenerationTemp
    );
}