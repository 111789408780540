import { ref } from "vue";
import { defineStore } from "pinia";
import {
  type GetApiSettingsResponse,
  type SettingDto,
} from "@/client";
import { SettingsService } from "@/client/";
import { InputTypes } from "@/interfaces/InputTypes";
import type { TemperatureSettings } from "@/features/settings/TemperatureSettings";

export const useSettingStore = defineStore("setting", () => {
  const settings = ref({} as SettingDto); 
  const defaultTemps: any = {
    freezer: {min: -40, max: -12},
    fridge: { min: 0, max: 8},
    cooking: {min: 75, max: 200},
    hotHolding: {min: 63, max: 200},
    coldHolding:{min: 0, max: 5},
    cooling: {min: 0, max: 8},
    coldProbe: {min: -1, max: 1},
    hotProbe:{ min: 99, max: 101},
    regeneration: { min: 75, max: 200}
  };

  async function get() {
    return await SettingsService.getApiSettings()
      .then(async (x: GetApiSettingsResponse) => {
        settings.value = x;
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  const getSettingsFor = (
    inputType: InputTypes,
  ): TemperatureSettings | null => {
    if (!settings.value) return null;

    const temperatureSettings: TemperatureSettings = {
      minimum: 0,
      maximum: 100,
    };

    switch (inputType) {
      case InputTypes.FreezerTemp:
        temperatureSettings.minimum = settings.value.freezer?.min!;
        temperatureSettings.maximum = settings.value.freezer?.max!;
        return temperatureSettings;
      case InputTypes.FridgeTemp:
        temperatureSettings.minimum = settings.value.fridge?.min!;
        temperatureSettings.maximum = settings.value.fridge?.max!;
        return temperatureSettings;
      case InputTypes.CookingTemp:
        temperatureSettings.minimum = settings.value.cooking?.min!;
        temperatureSettings.maximum = settings.value.cooking?.max!;
        return temperatureSettings;
      case InputTypes.HotHoldingTemp:
        temperatureSettings.minimum = settings.value.hotHolding?.min!;
        temperatureSettings.maximum = settings.value.hotHolding?.max!;
        return temperatureSettings;
      case InputTypes.CoolingTemp:
        temperatureSettings.minimum = settings.value.cooling?.min!;
        temperatureSettings.maximum = settings.value.cooling?.max!;
        return temperatureSettings;
      case InputTypes.ColdHoldingTemp:
        temperatureSettings.minimum = settings.value.coldHolding?.min!;
        temperatureSettings.maximum = settings.value.coldHolding?.max!;
        return temperatureSettings;
      case InputTypes.ColdProbeTemp:
        temperatureSettings.minimum = settings.value.coldProbe?.min!;
        temperatureSettings.maximum = settings.value.coldProbe?.max!;
        return temperatureSettings;
      case InputTypes.HotProbeTemp:
        temperatureSettings.minimum = settings.value.hotProbe?.min!;
        temperatureSettings.maximum = settings.value.hotProbe?.max!;
        return temperatureSettings;
      case InputTypes.RegenerationTemp:
          temperatureSettings.minimum = settings.value.regeneration?.min!;
          temperatureSettings.maximum = settings.value.regeneration?.max!;
          return temperatureSettings;
      default:
        return null;
    }
  };
  
  async function update(settings: SettingDto) {
    return await SettingsService.postApiSettings({requestBody: settings})
      .then(async (x: GetApiSettingsResponse) => {
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  return {
    get,
    update,
    getSettingsFor,
    defaultTemps,
    settings
  };
});
