import { createRouter, createWebHistory } from "vue-router";
import { BASE_URLS } from "@/config/urls";
import { useUserStore } from "@/stores";
import NProgress from "nprogress";
import { ROLES } from "@/config";
import { event } from 'vue-gtag'

NProgress.configure({ showSpinner: false, parent: "body" });

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: BASE_URLS.LOGIN,
      name: "login",
      meta: {
        title: "CheckMate",
        requiresAuth: false,
        hideUI: true,
      },
      component: () => import("@/views/auth/LoginView.vue"),
    },
    {
      path: BASE_URLS.REGISTER,
      name: "register",
      meta: {
        title: "CheckMate - Register",
        requiresAuth: false,
        hideUI: true,
      },
      component: () => import("@/views/auth/RegisterView.vue"),
    },
    {
      path: BASE_URLS.AUTHORISE,
      name: "authorise",
      meta: {
        title: "CheckMate - Authorise Account",
        requiresAuth: false,
        hideUI: true,
      },
      component: () => import("@/views/auth/AuthoriseView.vue"),
    },
    {
      path: BASE_URLS.HOME,
      name: "Calendar",
      meta: {
        title: "CheckMate - Calendar",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: true,
        icon: "fa fa-calendar",
      },
      component: () => import("@/views/checks/CalendarView.vue"),
    },
    {
      path: BASE_URLS.ISSUES,
      name: "Issues",
      meta: {
        title: "CheckMate - Issues",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: true,
        icon: "fas fa-exclamation-triangle",
      },
      component: () => import("@/views/issues/IssuesView.vue"),
    },
    {
      path: BASE_URLS.CHECKS,
      name: "Checks",
      meta: {
        title: "CheckMate - Checks",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: true,
        icon: "fa fa-square-check",
      },
      component: () => import("@/views/checks/ChecksView.vue"),
    },
    {
      path: BASE_URLS.EDITCHECK,
      name: "EditCheck",
      meta: {
        title: "CheckMate - Check",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: false,
        icon: "fa fa-square-check",
      },
      component: () => import("@/views/checks/EditCheckView.vue"),
    },
    {
      path: BASE_URLS.RECORD,
      name: "Record",
      meta: {
        title: "CheckMate - Audit Records",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
      },
      component: () => import("@/views/completedchecks/RecordView.vue"),
    },
    {
      path: BASE_URLS.DEVICES,
      name: "Devices",
      meta: {
        title: "CheckMate - Issues",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: false,
        icon: "fa fa-fire-burner",
      },
      component: () => import("@/features/devices/views/DevicesView.vue"),
    },
    {
      path: BASE_URLS.REPORTS,
      name: "Reports",
      meta: {
        title: "CheckMate - Reports",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: true,
        icon: "fa fa-chart-simple",
        order: 5,
      },
      component: () => import("@/views/reports/DownloadReportView.vue"),
    },
    {
      path: BASE_URLS.SETTINGS,
      name: "Settings",
      meta: {
        title: "CheckMate - Settings",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.CHECKMATE],
        inMenu: false,
        icon: "fa fa-cog",
        order: 7,
      },
      component: () => import("@/views/settings/SettingsView.vue"),
    },
    {
      path: BASE_URLS.LOCATIONS,
      name: "Locations",
      meta: {
        title: "CheckMate - Locations",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: false,
        icon: "fa fa-cog",
        order: 8,
      },
      component: () => import("@/views/location/LocationsView.vue"),
    },
    {
      path: BASE_URLS.NEWLOCATION,
      name: "New location",
      meta: {
        title: "CheckMate - New location",
        requiresAuth: true,
        roles: ["Admin", "Office", "CheckMate"],
        inMenu: false,
        icon: "fa fa-cog",
      },
      component: () => import("@/views/location/AddLocationView.vue"),
    },
    {
      path: BASE_URLS.EDITLOCATION,
      name: "Edit location",
      meta: {
        title: "CheckMate - Edit location",
        requiresAuth: true,
        roles: ["Admin", "Office", "CheckMate"],
        inMenu: false,
        icon: "fa fa-cog",
      },
      component: () => import("@/views/location/EditLocationView.vue"),
    },
    {
      path: BASE_URLS.USERS,
      name: "Users",
      meta: {
        title: "CheckMate - Users",
        requiresAuth: true,
        roles: [ROLES.ADMIN, ROLES.OFFICE, ROLES.CHECKMATE],
        inMenu: false,
        icon: "fa fa-cog",
        order: 9,
      },
      component: () => import("@/views/users/UsersView.vue"),
    },
    {
      path: BASE_URLS.RESET_PASSWORD,
      name: "PasswordReset",
      meta: {
        title: "CheckMate - Password Reset",
        requiresAuth: false,
        inMenu: false,
        hideUI: true 
      },
      component: () => import("@/views/auth/ResetPasswordView.vue"),
    },
    {
      path: BASE_URLS.TENANTS,
      name: "tenants",
      meta: {
        title: "CheckMate - Tenants",
        requiresAuth: true,
        roles: [ROLES.CHECKMATE],
        inMenu: false,
        hideUI: false 
      },
      component: () => import("@/views/tenants/TenantsView.vue"),
    },
    {
      path: BASE_URLS.PAYMENTRETURN,
      name: "paymentreturn",
      meta: {
        title: "CheckMate - Payment",
        requiresAuth: true,
        roles: [ROLES.CHECKMATE, ROLES.ADMIN],
        inMenu: false,
        hideUI: false 
      },
      component: () => import("@/views/settings/PaymentReturnView.vue"),
    },
    {
      path: "/api/payment/webhook/stripe",
      name: "stripewebhook",
      meta: {
        redirect: "/api/payment/webhook/stripe",
      },
      component: () => import("@/views/errors/NotFound.vue"),
    },
    {
      path: "/401",
      name: "unauthorized",
      meta: {
        title: "CheckMate - 401",
        requiresAuth: false,
        hideUI: true,
      },
      component: () => import("@/views/errors/NotAuthorized.vue"),
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      meta: { requiresAuth: false, title: "CheckMate - 404", hideUI: true },
      component: () => import("@/views/errors/NotFound.vue"),
    },
  ],
});

//  Permissions
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  event('page_view', { page_path: to.path });

  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }

  const title = to.matched.some((record) => record.meta.title);
  if (title) document.title = to.meta.title as string;

  const authRequired = to.matched.some((record) => record.meta.requiresAuth);

  if (to.meta.redirect != undefined) {
    window.location.assign(import.meta.env.VITE_API_URL + to.meta.redirect as string);
    return;
  }

  //  Normal unauthenticated page
  if (!authRequired) return next();

  //  Authenticated page
  if (userStore.isAuthenticated) {
    if (userStore.user == null) await userStore.getCurrentUser();
    if (userStore.user == null) userStore.logout();
    //  Role based permissions
    if (to.meta.roles && userStore.user) {
      const roles: string[] = to.meta.roles as string[];
      const inRole = roles.some((role) => {
        return [...userStore.user.roles].indexOf(role) > -1;
      });
      if (inRole) {
        return next();
      } else {
        return next({ path: "/401" });
      }
    }
  }
  return next({ path: BASE_URLS.LOGIN, query: { redirectFrom: to.fullPath } });
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
