export const login = {
    title: 'Sign in to your account',
    forgotPassword: 'Forgot Password?',
    notAMember: 'Not a member?',
    register: 'Register now!',
    forgotPasswordTitle: 'Forgot Password',
    forgotPasswordContent: "<p class='pb-3'>Enter your email address and we will send you a link to reset your password</p>",
    forgotPasswordButton: 'Send Reset Link',
    forgotPasswordSuccess: `<b>Password Reset Link Sent!</b><p class='pt-2'>If we found an account with the email address you entered, we've sent password reset instructions to the email address you provided. Please check your email.</p>`,
}

export const registration = {
    title: "Registration",
    mainContent: `<p class='py-3'>Signing up to CheckMate ensures your food safety records are secure and accurate. Using blockchain technology, CheckMate guarantees your records cannot be altered, providing a reliable and tamper-proof system. This reliability is crucial for maintaining compliance with food health and safety standards, helping you avoid legal issues and ensure customer safety.</p>
    <p>Register now to:</p>    
    <ul class="list-none px-10">
        <li class="p-1"><i class='fa fa-check text-primary'></i> Ensure your compliance with Food Health & Safety Agency guidance.</li>
        <li class="p-1"><i class='fa fa-check text-primary'></i> Have secure and unalterable records.</li>
        <li class="p-1"><i class='fa fa-check text-primary'></i> Speed up you your daily compliance checks.</li>
    </ul>`,
    register: "Register",
    registrationComplete: "Registration Complete",
    registrationCompleteContent: `<p class="py-3">Thanks for registering. You will soon receive an email asking for you to confirm your email address.</p>`,
    next: `Next`
}

export const authorize = {
    accountNotFound: `<h1 class="py-5 text-2xl text-center font-bold">Account could not be found</h1>
                        <p class="p-3">Your account could not be found registered on our system.</p>
                        <p class="p-3">Please ensure you clicked on the correct link in your registration email.</p>
                        <p class="p-3">If you have further issues please contact <a class="text-blue-900" href='mailto:help{'@'}checkmate.software'>help{'@'}checkmate.software</a></p>`,
    accountAlreadyAuthorized: `<h1 class="py-5 text-2xl text-center font-bold">Account is already authorized</h1>
                                <p class="p-3">Your account has already been authorized, try logging in</p>
                                <p class="p-3">If you have further issues please contact <a class="text-blue-900" href='mailto:help{'@'}checkmate.software'>help{'@'}checkmate.software</a></p>`,
    title: "Account Verified",
    passwordContent: `<p class="py-3">Thanks for verifying your account with CheckMate! While we're getting everything ready for you we have a few we questions to complete your account</p>
                        <p class="py-3">First we need to setup your password so you can access your account</p>`,
    checkSetup: "Setup your checks",
    checkSetupContent: `<p class="py-3">We have a set of template checks that follow the Smarter Business Food Safety Standards which I can automatically set up for you. </p>
                        <p class="py-3">To do that I need some information about your fridges, freezers and ovens. Give each one a name that you can remember and tell us what type is it.</p>
                        <p class="py-3">Note: This can be setup later, if you skip this section we will setup your initial checks with 1 fridge, 1 freezer and 1 oven.</p>`,
    skip: "Skip this step and setup later",
    resetpasswordsuccess: "Password Reset Successful",
}

export const devices = {
    myDevices: "My Devices",
    type: "Type",
    name: "Friendly Name",
    namePlaceholder: "Friendly Name (e.g. Under-counter Fridge)"
}

export const checks = {
    "iconAndName": "Icon & Name"
}

export const issues = {
    "addNote": "Add Note",
}

export const reports = {
    "reports": "Reports",
    "copyright": "Audit records for #location# generated on #date#. Provided by CheckMate",
    "completed": "Completed on ",
    "by": " by ",
    "daterange": "Date Range",
    "day": "Day",
    "daterangereport": "Date Range Check Report",
    "dayreport": "Daily Check Report",
    "download": "Generate & download report",
    "dayreportdownloaded": "Daily Check Report Generated Successfully",
    "daterangereportdownloaded": "Date Range Check Report Downloaded",
    "dayreportdownloadedmessage": "Your report has been generated and downloaded onto your device",
    "daterangereportdownloadedmessage": "Your report has been generated and downloaded onto your device",
    "noreport": "Report not available",
    "noreportmessage": "No data for this day"
}

export const user = {
    "firstname": "First Name",
    "lastname": "Last Name",
    "email": "Email",
    "phone": "Phone",
    "confirmpassword": "Confirm Password",
    "role": "User Type",
    "locations": "Locations",
    "available": "Available",
    "selected": "Selected",
    "resetpassword": "Password Reset",
    "btnresetpassword": "Reset Password",
}

export const settings = {
    "payment": {
        "create": `<div class="text-center p-10 flex items-center flex-col">
            <img src='/tick-only.png' class="inline" style='width: 20%' /> 
            <p class="p-10">Your payment details have been successfully added. </p>
        </div>`,
        "createtitle": "Success",
        "cancel": "Your cancellation has been processed, from this point on your historical data will still be accessible to you.",
        "canceltitle": "We're sorry to see you go",
        "update": `<div class="text-center p-10 flex items-center flex-col">
            <img src='/tick-only.png' class="inline" style='width: 20%' /> 
            <p class="p-10">You have successfully updated your payment method.</p>
        </div>`,
        "updatetitle": "Payment Update Successful",
        "fail": `<div class="text-center p-10 flex items-center flex-col">
            <i class="fa fa-times text-red-500" style="font-size: 18em"></i> 
            <p class="p-10">Your payment details failed to proccess, please try again. </p>
        </div>`,
        "failtitle": "Payment Failure",
        "paymenttitle": "Payment Processing..."
    },
    "subscription": {
        "confirmCancellationUpper": `<p class='mb-2'><strong>We're sorry to see you go</strong></p>
            <p class='mb-8'>Are you sure you want to unsubscribe from CheckMate? Staying subscribed means you'll continue to have access to all your peronsalised food safety checks and help your kitchen stay compliant.
            If you need assistance with anything, we are here to help just email us on <a class='text-blue-500' href='mailto:support{'@'}checkmate.software'>support{'@'}checkmate.software</a>.</p>
            <p class='mb-1'><strong>Before you go, please let us know how we can improve.</strong></p>`,
        "confirmCancellationLower": `<p class='mt-6 mb-2'>If your certain, just click below to confirm the end of your subscription. Thank you for using CheckMate, and remember your always welcome back!</p>`,
        "locations": "Locations",
        "location": "location",
        "cancel": "Cancel Subscription",
        "updateMethod": "Update Payment Method",
        "seeHowItWorks": "See how our pricing works",
        "costCalculation": "Cost is calculated based on number of locations.",
        "noOfLocations": "Number of active locations under this account",
        "cost": "Cost",
        "nextPaymentDue": "Next Payment Due",
        "usefulPaymentLinks": "Useful payment links",
        "paymentHistory": "Payment History",
        "date": "Date",
        "description": "Description",
        "amount": "Amount",
        "btnUnsubscribe": "Yes, I want to unsubscribe",
        "btnStaySubscribed": "No, take me back",
    }

}