export const BASE_URLS = {
  

    HOME: "/",
    LOGIN: "/login",
    SET_PASSWORD: "/set-password",
    RESET_PASSWORD: "/reset-password/:email",
    FORGOT_PASSWORD: "/forgot-password",
    REGISTER: "/register",
    AUTHORISE: "/authorise/:tenantid",
    CHECKS: "/checks",
    EDITCHECK: "/check/:id",
    ISSUES: "/issues",
    REPORTS: "/reports",
    DEVICES: "/devices",
    RECORD: "/record/:id",
    SETTINGS: "/settings",
    LOCATIONS: "/locations",
    USERS: "/users",
    TENANTS: "/accounts",
    NEWLOCATION: "/locations/add",
    EDITLOCATION: "/locations/edit/:id",
    PAYMENTRETURN: "/payment-return",
  };
  